import React, { useState, useEffect, useReducer } from 'react'
import './App.css'
import ToursListPage from './ToursListPage'
import TourPage from './TourPage'
import LogIn from './LogIn'
import tourReducer from './tourReducer'
import routeReducer from './routeReducer'
import accountReducer from './accountReducer'
import UserPage from './UserPage'
import { shuttleApi } from './config'
import { TOUR_NO_VIEW } from './utils'
import { UPDATE_ACCOUNT_FROM_SERVER } from './utils'

export const TourContext = React.createContext({})
export const RouteContext = React.createContext({})
export const AccountContext = React.createContext({})

const App = () => {
  const initialAccountState = {
    user: {}
  }

  const initialRouteState = {
    original: {},
    working: {},
    hasErrors: false,
    selected: null,
    dirty: false
  }

  const initialTourState = {
    original: {},
    working: {},
    hasErrors: false,
    view: {
      type: TOUR_NO_VIEW,
      data: {}
    },
    dirty: false
  }

  const [page, setPage] = useState({ page: '' })
  const [tour, dispatchTour] = useReducer(tourReducer, initialTourState)
  const [route, dispatchRoute] = useReducer(routeReducer, initialRouteState)
  const [account, dispatchAccount] = useReducer(
    accountReducer,
    initialAccountState
  )

  useEffect(() => {
    const checkUserIfAlreadyLoggedIn = async () => {
      try {
        const res = await fetch(`${shuttleApi}/api/check-login`, {
          headers: {
            'Content-Type': 'application/json'
          },
          credentials: 'include',
        })
        const data = await res.json()
        console.log(data)
        if (data.id) {
          dispatchAccount({
            type: UPDATE_ACCOUNT_FROM_SERVER,
            payload: {
              user: data
            }
          })
          setPage({ page: 'ToursListPage' })
        } else {
          setPage({ page: 'login' })
        }
      } catch (err) {
        console.log(err)
        setPage({ page: 'login' })
      }
    }
    checkUserIfAlreadyLoggedIn()
  }, [])

  return (
    <AccountContext.Provider value={{ account, dispatchAccount }}>
      <RouteContext.Provider value={{ route, dispatchRoute }}>
        <TourContext.Provider value={{ tour, dispatchTour }}>
          <div className='App'>
            {page.page === 'login' ? (
              <LogIn page={page} setPage={setPage} />
            ) : page.page === 'ToursListPage' ? (
              <ToursListPage page={page} setPage={setPage} />
            ) : page.page === 'TourPage' ? (
              <TourPage page={page} setPage={setPage} />
            ) : page.page === 'UserPage' ? (
              <UserPage page={page} setPage={setPage} />
            ) : null}
          </div>
        </TourContext.Provider>
      </RouteContext.Provider>
    </AccountContext.Provider>
  )
}

export default App

import React, { useState } from 'react'
import Drawer from '@material-ui/core/Drawer'
import Divider from '@material-ui/core/Divider'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemText from '@material-ui/core/ListItemText'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import Button from '@material-ui/core/Button'
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction'
import DragHandleIcon from '@material-ui/icons/DragHandle'
import { Container, Draggable } from 'react-smooth-dnd'
import InboxIcon from '@material-ui/icons/Inbox'
import Paper from '@material-ui/core/Paper'
import CloseIcon from '@material-ui/icons/Close'
import Tooltip from '@material-ui/core/Tooltip'
import RemoveTourPointAlertDialog from './RemoveTourPointAlertDialog'
import { isTourPoint, isInfoPoint, isSFXPoint, isViaPoint } from './utils'
import { createNewPointJSON } from './utils'
import {
  TOUR_NO_VIEW,
  TOUR_MEDIA,
  TOUR_POINT_EDITOR,
  TOUR_NEW_POINT,
  TOUR_EDITOR,
  TOUR_VIEWER
} from './utils'
import {
  UPDATE_TOURPOINT,
  CHANGE_TOURPOINT_TYPE,
  REORDER_TOURPOINTS,
  REMOVE_TOURPOINT,
  CREATE_TOURPOINT,
  RESET_STATE,
  UPDATE_FROM_SERVER,
  SAVE
} from './utils'

const TourPointItem = ({ value, draggable, dispatch }) => {
  const [alertOpen, setAlertOpen] = useState(false)

  const handleCancelAlert = () => {
    setAlertOpen(false)
  }

  const handleAcceptAlert = () => {
    removeTourPoint()
    setAlertOpen(false)
  }

  const removeTourPoint = () => {
    dispatch({ type: REMOVE_TOURPOINT, payload: value })
  }

  return (
    <>
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center'
        }}
        className='draggable-item'
        onClick={() =>
          dispatch({
            type: TOUR_POINT_EDITOR,
            payload: value
          })
        }
      >
        <div>
          {draggable && <b>&#9776;</b>} {value.title.sv.slice(0, 20)}
        </div>
        <div style={{ display: 'flex', alignItems: 'center', paddingRight: 5 }}>
          <Tooltip title='Ta bort punkt'>
            <CloseIcon
              fontSize='small'
              onClick={(e) => {
                e.stopPropagation()
                setAlertOpen(true)
              }}
            />
          </Tooltip>
        </div>
      </div>
      <RemoveTourPointAlertDialog
        open={alertOpen}
        handleCancel={handleCancelAlert}
        handleAccept={handleAcceptAlert}
      />
    </>
  )
}

const TourPageSideBar = ({
  classes,
  setPage,
  page,
  tour,
  selectedRoute,
  dispatch
}) => {
  return (
    <Paper elevation={1} className={classes.sPaper} style={{maxHeight: '100vh', overflow: 'auto', marginBottom: 0, paddingBottom: 20}}>
      <div className={classes.toolbar}>
        <Button
          variant='contained'
          onClick={() => setPage({ ...page, page: 'ToursListPage' })}
        >
          Tillbaka
        </Button>
      </div>

      <ListItem
        button
        key='TourBtn'
        onClick={() =>
          dispatch({
            type: TOUR_EDITOR,
            payload: {}
          })
        }
      >
        <ListItemText primary='Ändra Rundan' />
      </ListItem>

      <Divider />

      <ListItem
        button
        key='TourViewBtn'
        onClick={() =>
          dispatch({
            type: TOUR_VIEWER,
            payload: {}
          })
        }
      >
        <ListItemText primary='Visa Rundan' />
      </ListItem>
      <Divider />
      <h3>Rundans punkter</h3>
      <Button
        className={classes.addBtn}
        variant='contained'
        color='default'
        onClick={() => {
          const init = createNewPointJSON()
          const json = {
            ...init,
            latitude: selectedRoute.latitude,
            longitude: selectedRoute.longitude
          }
          dispatch({ type: CREATE_TOURPOINT, payload: json })
        }}
      >
        Lägg till ny punkt
      </Button>
      <br />
      <Divider />
      <h4>Rund- och viapunkter</h4>
      <div>
        <Container
          onDrop={(e) => dispatch({ type: REORDER_TOURPOINTS, payload: e })}
        >
          {tour.places != null &&
            tour.places
              .filter((a) => a.ordernumber !== 0)
              .sort((a, b) => a.ordernumber - b.ordernumber)
              .map((value, index) => {
                if (isTourPoint(value) || isViaPoint(value)) {
                  return (
                    <Draggable key={value._id}>
                      <TourPointItem
                        draggable
                        dispatch={dispatch}
                        value={value}
                      />
                    </Draggable>
                  )
                }
                return null
              })}
        </Container>
      </div>
      <Divider />
      <br />
      <h4>Infopunkter</h4>
      <Container>
        {tour.places != null &&
          tour.places.map((value, index) =>
            isInfoPoint(value) ? (
              <div key={index}>
                <TourPointItem dispatch={dispatch} value={value} />
              </div>
            ) : null
          )}
      </Container>
      <Divider />
      <h4>Ljudpunkter</h4>
      <List>
        {tour.places != null &&
          tour.places.map((value, index) => {
            if (isSFXPoint(value)) {
              return (
                <div key={index}>
                  <TourPointItem dispatch={dispatch} value={value} />
                </div>
              )
            }
            return null
          })}
      </List>
      <Divider />
      <Divider />
      <h4>Media</h4>
      <Button
        className={classes.addBtn}
        variant='contained'
        color='default'
        onClick={() =>
          dispatch({
            type: TOUR_MEDIA,
            payload: 'HTML_IMAGE'
          })
        }
      >
        Bilder
      </Button>
      <Button
        className={classes.addBtn}
        variant='contained'
        color='default'
        onClick={() =>
          dispatch({
            type: TOUR_MEDIA,
            payload: 'SOUND'
          })
        }
      >
        Ljud
      </Button>
      <Button
        className={classes.addBtn}
        variant='contained'
        color='default'
        onClick={() =>
          dispatch({
            type: TOUR_MEDIA,
            payload: 'VIDEO'
          })
        }
      >
        Filmer
      </Button>
    </Paper>
  )
}

export default TourPageSideBar

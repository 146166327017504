import React, { useState, useEffect, useRef } from 'react'
import ReactDOM from 'react-dom'
import { TourContext, RouteContext } from './App'
//import GoogleMapContainer from './gMaps'
import {
  Map,
  InfoWindow,
  Marker,
  Circle,
  GoogleApiWrapper
} from 'google-maps-react'

import { TOUR_POINT_EDITOR } from './utils'

const InfoWindowEx = props => {
  const infoWindowRef = React.createRef()
  const contentElement = document.createElement(`div`)
  useEffect(() => {
    ReactDOM.render(React.Children.only(props.children), contentElement)
    infoWindowRef.current.infowindow.setContent(contentElement)
  }, [props.children])
  return <InfoWindow ref={infoWindowRef} {...props} />
}

//const mapW = 100%
const mapH = 600

const TourViewer = ({ google, data }) => {
  const [state, setState] = useState({
    activeMarker: {},
    selectedPlace: {},
    showingInfoWindow: false
  })

  const { dispatchTour: dispatch } = React.useContext(TourContext)
  const { route } = React.useContext(RouteContext)

  const cords = { lat: route.selected.latitude, lng: route.selected.longitude }
  const mapWid = '100%'
  const mapHei = 600

  const onMarkerClick = (props, marker) => {
    const { value } = props
    console.log(value)
    console.log(marker)
    console.log(props)
    setState({
      activeMarker: marker,
      selectedPlace: props,
      showingInfoWindow: true
    })
  }
  const onInfoWindowClose = () =>
    setState({
      activeMarker: null,
      showingInfoWindow: false
    })

  const onInfoWindowClick = () => {
    dispatch({ type: TOUR_POINT_EDITOR, payload: state.selectedPlace.value })
  }

  const onMapClicked = () => {
    if (state.showingInfoWindow)
      setState({
        activeMarker: null,
        showingInfoWindow: false
      })
  }

  return (
    <div>
      <div>
        <Map
          google={google}
          zoom={18}
          containerStyle={{ position: 'relative' }}
          style={{
            width: mapWid,
            height: mapHei
          }}
          initialCenter={cords}
        >
          {data.places != null &&
            data.places.map((value, index) => {
              return (
                <Circle
                  radius={value.radius}
                  center={{
                    lat: Number(value.latitude),
                    lng: Number(value.longitude)
                  }}
                  strokeColor='transparent'
                  strokeOpacity={0}
                  strokeWeight={5}
                  fillColor='#FF0000'
                  fillOpacity={0.2}
                />
              )
            })}
          {data.places != null &&
            data.places.map((value, index) => {
              let iconSize = new google.maps.Size(55, 55)
              let urlPin =
                'https://maps.google.com/mapfiles/ms/icons/blue-dot.png'
              if (value.placeTypeId === 1) {
                urlPin =
                  'https://chart.apis.google.com/chart?chst=d_map_pin_letter&chld=' +
                  value.ordernumber +
                  '|FE6256|000000'
                iconSize = new google.maps.Size(35, 55)
              } else if (value.placeTypeId === 4) {
                urlPin =
                  'https://chart.apis.google.com/chart?chst=d_map_pin_letter&chld=' +
                  value.ordernumber +
                  '|fed156|000000'
                iconSize = new google.maps.Size(35, 55)
              } else if (value.placeTypeId === 3) {
                urlPin =
                  'https://maps.google.com/mapfiles/ms/icons/green-dot.png'
              }
              return (
                <Marker
                  value={value}
                  title={value.title.sv}
                  onClick={onMarkerClick}
                  key={value._id}
                  name={value.title.sv}
                  position={{ lat: value.latitude, lng: value.longitude }}
                  icon={{
                    url: urlPin,
                    scaledSize: iconSize
                  }}
                />
              )
            })}
          <InfoWindowEx
            marker={state.activeMarker}
            onClose={onInfoWindowClose}
            visible={state.showingInfoWindow}
          >
            <div style={{ cursor: 'pointer' }} onClick={onInfoWindowClick}>
              <h4>{state.selectedPlace != null && state.selectedPlace.name}</h4>
            </div>
          </InfoWindowEx>
        </Map>
      </div>
    </div>
  )
}
export default GoogleApiWrapper({
  apiKey: 'AIzaSyA6jdWmCAWoFUMrfWoi9j6-tFpyVTz54ns'
})(TourViewer)

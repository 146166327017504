import React, { useState, useEffect, useCallback, useContext } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'
import Card from '@material-ui/core/Card'
import {
  FormControl,
  FormHelperText,
  InputLabel,
  Input,
  FormGroup
} from '@material-ui/core'
import Paper from '@material-ui/core/Paper'
import CardActionArea from '@material-ui/core/CardActionArea'
import CardContent from '@material-ui/core/CardContent'
import Typography from '@material-ui/core/Typography'
import CardMedia from '@material-ui/core/CardMedia'
import Button from '@material-ui/core/Button'
import MUICookieConsent from 'material-ui-cookie-consent'
import ReactHtmlParser from 'react-html-parser'
import { AccountContext } from './App'
import { shuttleApi } from './config.js'
import { UPDATE_ACCOUNT_FROM_SERVER } from './utils'
import Logo2 from './logo3.png'
import BackImgA from './back4a.jpg'
import BackImgB from './back4b.png'

const Email = ({ email, setEmail, onSubmit }) => {
  return (
    <form
      style={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center'
      }}
      onSubmit={onSubmit}
    >
      <div style={{ display: 'flex', width: '100%', justifyContent: 'center' }}>
        <h1>Åteställ lösen</h1>
      </div>
      <FormControl required>
        <InputLabel htmlFor='email'>Email</InputLabel>
        <Input
          key={'email'}
          id='email'
          type='email'
          aria-describedby='enter email'
          value={email}
          onChange={(e) => setEmail(e.target.value)}
        />
        <FormHelperText id='set-email'></FormHelperText>
      </FormControl>
      <div style={{ display: 'flex', marginTop: 30, alignItems: 'flex-end' }}>
        <Button type='submit' variant='contained'>
          Skicka email address
        </Button>
      </div>
    </form>
  )
}

const PasswordAndCode = ({
  password1,
  setPassword1,
  password2,
  setPassword2,
  code,
  setCode,
  onSubmit
}) => {
  const validateForm = useCallback(() => {
    return (
      password1.length > 0 &&
      password2.length > 0 &&
      password1 === password2 &&
      code > 5
    )
  }, [password1, password2, code])

  return (
    <form
      style={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center'
      }}
      onSubmit={onSubmit}
    >
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          width: '100%',
          alignItems: 'center'
        }}
      >
        <h1>Återställ Lösen</h1>
        Kolla din mail för verificeringskod
      </div>
      <FormGroup>
        <FormControl required>
          <InputLabel htmlFor='password1'>Lösenord</InputLabel>
          <Input
            id='password1'
            type='password'
            aria-describedby='choose-password'
            value={password1}
            onChange={(e) => setPassword1(e.target.value)}
          />
          <FormHelperText id='choose-pasword'></FormHelperText>
        </FormControl>
        <FormControl required error={password1 !== password2}>
          <InputLabel htmlFor='password2'>Upprepa lösenordet</InputLabel>
          <Input
            id='password2'
            type='password'
            aria-describedby='repeat-password'
            value={password2}
            onChange={(e) => setPassword2(e.target.value)}
          />
          <FormHelperText id='reset-pasword'></FormHelperText>
        </FormControl>
      </FormGroup>
      <FormControl required>
        <InputLabel htmlFor='code'>Kod från e-post</InputLabel>
        <Input
          id='code'
          type='text'
          aria-describedby='code'
          value={code}
          onChange={(e) => setCode(e.target.value)}
        />
        <FormHelperText id='reset-code'></FormHelperText>
      </FormControl>
      <div style={{ display: 'flex', marginTop: 30, alignItems: 'flex-end' }}>
        <Button type='submit' variant='contained' disabled={!validateForm()}>
          Ändra lösen
        </Button>
      </div>
    </form>
  )
}

const ResetPassword = ({ setResetMail }) => {
  const [email, setEmail] = useState('')
  const [emailSent, setEmailSent] = useState(false)
  const [password1, setPassword1] = useState(undefined)
  const [password2, setPassword2] = useState(undefined)
  const [code, setCode] = useState(undefined)

  const onSubmit = async (e) => {
    e.preventDefault()
    try {
      fetch(`${shuttleApi}/reset-password`, {
        credentials: 'include',
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({ email, password: password1, code })
      })
        .then((res) => res.json())
        .then((res) => {
          console.log(res)
          if (res.status === 'success') {
            console.log('we read success from status')
            if (res.data === 'password changed') {
              alert('Lösen bytt - du kan nu logga in')
              setResetMail(false)
            } else {
              console.log('we should now show change password form')
              setPassword1('')
              setPassword2('')
              setCode('')
              setEmailSent(true)
            }
          }
        })
    } catch (e) {
      console.log(e)
    }
  }

  return !emailSent ? (
    <Email
      email={email}
      setEmail={setEmail}
      setEmailSent={setEmailSent}
      onSubmit={onSubmit}
    />
  ) : (
    <PasswordAndCode
      password1={password1}
      setPassword1={setPassword1}
      password2={password2}
      setPassword2={setPassword2}
      code={code}
      setCode={setCode}
      onSubmit={onSubmit}
    />
  )
}

const LogIn = ({ setSignUp, setResetMail, onLogin }) => {
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')

  const { dispatchAccount: dispatch } = useContext(AccountContext)

  const onSubmit = async (e) => {
    e.preventDefault()
    try {
      const res = await fetch(`${shuttleApi}/login`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        credentials: 'include',
        body: JSON.stringify({ email, password })
      })
      const data = await res.json()
      console.log(data)
      if (data.id) {
        dispatch({
          type: UPDATE_ACCOUNT_FROM_SERVER,
          payload: {
            user: data
          }
        })
        onLogin()
      } else {
        alert('Failed username or password')
      }
    } catch (err) {
      console.log(err)
    }
  }

  const validateForm = () => {
    return email.length > 0 && password.length > 0
  }

  return (
    <Paper elevation={3}>
      <form
        style={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          padding: '10px'
        }}
        onSubmit={onSubmit}
      >
        <div
          style={{ display: 'flex', justifyContent: 'center', width: '100%' }}
        >
          <h4>Logga in</h4>
        </div>
        <FormControl required>
          <InputLabel htmlFor='email'>Email address</InputLabel>
          <Input
            id='email'
            type='email'
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            aria-describedby='email-never-share'
          />
          <FormHelperText id='email-never-share'></FormHelperText>
        </FormControl>
        <FormControl required>
          <InputLabel htmlFor='password'>Lösenord</InputLabel>
          <Input
            id='password'
            type='password'
            aria-describedby='forgot-password'
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
          <FormHelperText id='forgot-pasword'>
            Om du glömt ditt lösen ord klicka{' '}
            <span
              style={{ textDecoration: 'underline', cursor: 'pointer' }}
              onClick={() => setResetMail(true)}
            >
              här
            </span>
          </FormHelperText>
        </FormControl>
        <div style={{ display: 'flex', marginTop: 30, alignItems: 'flex-end' }}>
          <Button type='submit' variant='contained' disabled={!validateForm()}>
            Logga In
          </Button>
          <div style={{ marginLeft: 10 }} className='MuiFormHelperText-root'>
            Inte registrerad än?
            <span
              style={{ textDecoration: 'underline', cursor: 'pointer' }}
              onClick={() => setSignUp(true)}
            >
              skapa konto
            </span>
          </div>
        </div>
      </form>
    </Paper>
  )
}

const SignUp = ({ setSignUp }) => {
  const [organization, setOrganization] = useState('')
  const [email, setEmail] = useState('')
  const [password1, setPassword1] = useState('')
  const [password2, setPassword2] = useState('')

  const validateForm = () => {
    return (
      organization.length > 0 &&
      email.length > 0 &&
      password1.length > 0 &&
      password2.length > 0 &&
      password1 === password2
    )
  }

  const onSubmit = (e) => {
    e.preventDefault()
    fetch(`${shuttleApi}/signup`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      credentials: 'include',
      body: JSON.stringify({ email, password1, password2, organization })
    })
      .then((res) => res.json())
      .then((res) => {
        if (res.status === 'success') {
          alert('Check your mail for verification email')
        }
        console.log(res)
      })
  }

  return (
    <form
      style={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center'
      }}
      onSubmit={onSubmit}
    >
      <div style={{ display: 'flex', width: '100%', justifyContent: 'center' }}>
        <h1>Sign Up</h1>
      </div>
      <FormControl required>
        <InputLabel htmlFor='organization'>Välj ett kontonamn</InputLabel>
        <Input
          id='organization'
          value={organization}
          onChange={(e) => setOrganization(e.target.value)}
          aria-describedby='organization-help'
        />
        <FormHelperText id='organization-help'></FormHelperText>
      </FormControl>
      <FormControl required>
        <InputLabel htmlFor='email'>Email address</InputLabel>
        <Input
          id='email'
          type='email'
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          aria-describedby='email-never-share'
        />
        <FormHelperText id='email-never-share'></FormHelperText>
      </FormControl>
      <FormGroup>
        <FormControl required>
          <InputLabel htmlFor='password'>Lösenord</InputLabel>
          <Input
            id='password1'
            type='password'
            aria-describedby='choose-password'
            value={password1}
            onChange={(e) => setPassword1(e.target.value)}
          />
          <FormHelperText id='choose-pasword'></FormHelperText>
        </FormControl>
        <FormControl required error={password1 !== password2}>
          <InputLabel htmlFor='password'>Upprepa lösenordet</InputLabel>
          <Input
            id='password2'
            type='password'
            aria-describedby='forgot-password'
            value={password2}
            onChange={(e) => setPassword2(e.target.value)}
          />
          <FormHelperText id='forgot-pasword'></FormHelperText>
        </FormControl>
      </FormGroup>
      <div style={{ display: 'flex', marginTop: 30, alignItems: 'flex-end' }}>
        <Button type='submit' variant='contained' disabled={!validateForm()}>
          Signa Upp
        </Button>
        <div style={{ marginLeft: 10 }} className='MuiFormHelperText-root'>
          Redan användare?{' '}
          <span
            style={{ textDecoration: 'underline', cursor: 'pointer' }}
            onClick={() => setSignUp(false)}
          >
            Logga in
          </span>
        </div>
      </div>
    </form>
  )
}

const Authenticate = ({ page, setPage }) => {
  const [isSignUp, setSignUp] = useState(false)
  const [resetMail, setResetMail] = useState(false)

  const onLogin = () => setPage({ ...page, page: 'ToursListPage' })

  return (
    <div style={{ height: '100vh' }}>
      <MUICookieConsent
        cookieName='cookieConsent'
        cookieValue='accepted'
        componentType='Snackbar' // default value is Snackbar
        message={ReactHtmlParser(
          'This site uses cookies in order to work. You can read our <a style="color:white;" target="_blank" href="http://www.do-fi.se/shuttle/index.php/privacy-policy">privacy policy</a> for more information. By continuing to use this site you automatically agree to this.'
        )}
        snackbarAnchor={{ horizontal: 'center', vertical: 'center' }}
      />
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          height: '30vh',
          marginBottom: 50,
          alignItems: 'center'
        }}
      >
        <img
          style={{
            width: '37.5vh',
            height: '12.5vh'
          }}
          src={Logo2}
        />
      </div>
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center'
        }}
      >
        {resetMail ? (
          <ResetPassword setResetMail={setResetMail} />
        ) : isSignUp ? (
          <SignUp setSignUp={setSignUp} />
        ) : (
          <LogIn
            setSignUp={setSignUp}
            onLogin={onLogin}
            setResetMail={setResetMail}
          />
        )}
      </div>
      <div
        style={{
          marginTop: '5vh'
        }}
      >
        Läs mer om shuttle{' '}
        <a href='http://www.do-fi.se/shuttle/' target='_blank'>
          här
        </a>
      </div>

      <img
        style={{
          width: '50vh',
          height: '80vh',
          position: 'fixed',
          bottom: '0',
          left: '0'
        }}
        src={BackImgA}
      />
      <img
        style={{
          width: '40vh',
          height: '70vh',
          position: 'fixed',
          bottom: '0',
          right: '0'
        }}
        src={BackImgB}
      />
    </div>
  )
}

export default Authenticate

import React, { useContext, useState, useEffect, useCallback } from 'react'
import Paper from '@material-ui/core/Paper'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Switch from '@material-ui/core/Switch'
import GoogleMapContainer from './gMaps'
import { makeStyles } from '@material-ui/core/styles'
import { Editor } from 'react-draft-wysiwyg'
import { EditorState, ContentState, convertFromHTML } from 'draft-js'
import { stateToHTML } from 'draft-js-export-html'
import Tooltip from '@material-ui/core/Tooltip'
import Button from '@material-ui/core/Button'
import Select, { components } from 'react-select'
import DeleteRouteAlertDialog from './DeleteRouteAlertDialog'
import { TourContext, RouteContext } from './App'
import { shuttleApi, mediaUrl } from './config'
import { useDebounce } from './utils'
import { isImageType } from './utils'
import { UPDATE_ROUTE } from './utils'

const mapW = 400
const mapH = 400

const useStyles = makeStyles({
  main: {
    display: 'flex',
    flexWrap: 'wrap',
    //flexDirection: 'column',
    width: '100%'
  },
  r1: {
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
    flexFlow: 'column wrap'
  },
  r1L: {
    flex: `calc(100% - ${mapW}px - 10px)`,
    marginRight: 20
  },
  r1R: {
    flex: mapW,
    marginTop: 0,
    marginRight: '0px',
    flexDirection: 'column'
  },
  underMap: {
    marginTop: mapH + 20,
    width: '100%'
  },
  singleLineTxtField: {
    width: '100%',
    marginLeft: 0,
    fontSize: 20,
    paddingTop: 5
  },
  mGrid: {
    width: '30%',
    marginRight: 20,
    marginLeft: 20,
    backgroundColor: '#009900'
  },
  mLeft: {},
  mPaper: {
    marginBottom: 20,
    padding: 20,
    paddingTop: 5
  },
  publishOrDelete: {
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: 10
  }
})

const ImageValue = (props) => {
  const value = props.data.val
  const id = props.selectProps.selectProps.id
  const image = `${mediaUrl}/${id}/images/${value}`
  return (
    <div style={{ height: '4rem' }}>
      <components.SingleValue {...props}>
        <div>
          <img style={{ width: '100px', height: '100px' }} src={image} alt='' />
        </div>
      </components.SingleValue>
    </div>
  )
}

const ImageOption = (props) => {
  const id = props.selectProps.selectProps.id
  const image = `${mediaUrl}/${id}/images/${props.data._id}`
  return (
    <components.Option {...props}>
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <img
          style={{ width: '50px', height: '50px' }}
          src={image}
          alt={props.data.name}
        />
        <div style={{ paddingLeft: 50 }}>{props.data.name}</div>
      </div>
    </components.Option>
  )
}

const TourEditor = ({ setPage, data }) => {
  const classes = useStyles()
  console.log(data)
  const initialState = {
    editorState: EditorState.createWithContent(
      ContentState.createFromBlockArray(convertFromHTML(data.htmlContent.sv))
    ),
    dirty: false
  }

  const [openDeleteDialog, setOpenDeleteDialog] = useState(false)
  const [state, setState] = useState(initialState)
  const { tour } = useContext(TourContext)
  const { dispatchRoute: dispatch } = useContext(RouteContext)

  const debouncedEditorSave = useDebounce(state, 300)

  useEffect(() => {
    console.log('inside first use effect')
    setState({
      ...state,
      editorState: EditorState.moveFocusToEnd(
        EditorState.createWithContent(
          ContentState.createFromBlockArray(
            convertFromHTML(data.htmlContent.sv)
          )
        )
      ),
      dirty: false
    })
  }, [data._id])

  useEffect(() => {
    if (debouncedEditorSave && state.dirty === true) {
      console.log('updating from editor')
      dispatch({
        type: UPDATE_ROUTE,
        payload: {
          ...data,
          htmlContent: {
            ...data.htmlContent,
            sv: stateToHTML(debouncedEditorSave.editorState.getCurrentContent())
          }
        }
      })
      setState({ ...state, dirty: false })
    }
  }, [debouncedEditorSave])

  const onEditorStateChange = (editorState) => {
    if (editorState._immutable.lastChangeType != null) {
      console.log('we are in editorstate')
      console.log(stateToHTML(editorState.getCurrentContent()))
      setState({
        ...state,
        editorState,
        dirty: true
      })
    }
  }

  const onTitleChange = (e) => {
    dispatch({
      type: UPDATE_ROUTE,
      payload: {
        ...data,
        titles: { ...data.titles, sv: e.target.value }
      }
    })
  }

  const onImageChange = (value) => {
    dispatch({
      type: UPDATE_ROUTE,
      payload: {
        ...data,
        guideImage: value ? value._id : ''
      }
    })
  }

  const onSimpleFieldChange = (field) => (e) => {
    dispatch({
      type: UPDATE_ROUTE,
      payload: {
        ...data,
        [`${field}`]: e.target.value
      }
    })
  }

  const onPublishedChange = (e) => {
    dispatch({
      type: UPDATE_ROUTE,
      payload: {
        ...data,
        published: e.target.checked
      }
    })
  }

  const onCodeSwitchChange = (e) => {
    dispatch({
      type: UPDATE_ROUTE,
      payload: {
        ...data,
        code: {
          ...data.code,
          active: e.target.checked
        }
      }
    })
  }

  const onOpenDeleteRouteAlertDialog = () => setOpenDeleteDialog(true)

  const onDeleteRoute = async () => {
    try {
      const res = await fetch(`${shuttleApi}/api/route/delete`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        credentials: 'include',
        body: JSON.stringify({ _id: data._id })
      })
      console.log(res)
      setPage({ page: 'ToursListPage' })
    } catch (err) {
      console.log(err)
    }
  }

  const onLatitudeChange = onSimpleFieldChange('latitude')
  const onLongitudeChange = onSimpleFieldChange('longitude')
  const onPlaceChange = onSimpleFieldChange('guideArea')
  const onGuideTimeChange = onSimpleFieldChange('guideTime')
  const onGuideLengthChange = onSimpleFieldChange('guideLength')

  const onMapsMarkerDragEnd = useCallback(
    (coordinates) => {
      const { latLng } = coordinates
      dispatch({
        type: UPDATE_ROUTE,
        payload: {
          ...data,
          latitude: latLng.lat(),
          longitude: latLng.lng()
        }
      })
    },
    [data]
  )

  const imageOptions = tour.working.contentMap.filter(isImageType)

  return (
    <div className={classes.main}>
      <DeleteRouteAlertDialog
        open={openDeleteDialog}
        setOpen={setOpenDeleteDialog}
        onDeleteRoute={onDeleteRoute}
      />
      <div className={classes.r1}>
        <div className={classes.r1L}>
          <div className={classes.publishOrDelete}>
            <FormControlLabel
              control={
                <Switch
                  checked={data.published}
                  onChange={onPublishedChange}
                  name='published'
                  color='primary'
                  inputProps={{ 'aria-label': 'primary checkbox' }}
                />
              }
              label='Publicera'
            />
            <FormControlLabel
              control={
                <Switch
                  checked={data.code?.active}
                  onChange={onCodeSwitchChange}
                  name='code'
                  color='primary'
                  inputProps={{ 'aria-label': 'primary checkbox' }}
                />
              }
              label={`Lås med kod: ${data.code?.number
                ?.toString?.()
                .padStart?.(6, '0')}`}
            />
            <Button
              variant='contained'
              color='secondary'
              onClick={onOpenDeleteRouteAlertDialog}
            >
              Radera runda
            </Button>
          </div>
          <Paper elevation={1} className={classes.mPaper}>
            <h3>Rundans titel:</h3>
            <input
              className={classes.singleLineTxtField}
              type='text'
              id='title'
              name='title'
              value={data.titles.sv}
              onChange={onTitleChange}
            />
          </Paper>
          <Paper elevation={1} className={classes.mPaper}>
            <h3>Introtext:</h3>
            <Editor
              toolbar={{
                options: ['inline', 'list', 'fontSize'],
                inline: {
                  inDropdown: false,
                  className: undefined,
                  component: undefined,
                  dropdownClassName: undefined,
                  options: ['bold', 'italic', 'underline']
                }
              }}
              toolbarClassName='toolbarClassName'
              wrapperClassName='home-wrapper'
              editorClassName='home-editor'
              editorState={state.editorState}
              //value={data.htmlContent.sv}
              onEditorStateChange={onEditorStateChange}
              defaultValue={data.htmlContent.sv}
            />
          </Paper>
          <Paper elevation={1} className={classes.mPaper}>
            <h3>Rundans bild:</h3>
            <div>
              <Select
                classNamePrefix='react-select'
                placeholder={'Välj bild...'}
                value={data.guideImage !== '' ? { val: data.guideImage } : null}
                selectProps={{ id: tour.working._id }}
                getOptionValue={(option) => option}
                //getLabelValue={(option) => option.name}
                components={{
                  Option: ImageOption,
                  SingleValue: ImageValue
                }}
                onChange={onImageChange}
                options={imageOptions}
                menuPlacement='auto'
                openMenuOnClick={true}
                closeMenuOnSelect={true}
                isSearchable={false}
                isClearable={true}
              />
            </div>
          </Paper>
          <Paper elevation={1} className={classes.mPaper}>
            <h3>Område:</h3>
            <input
              className={classes.singleLineTxtField}
              type='text'
              id='guideArea'
              name='guideArea'
              value={data.guideArea}
              onChange={onPlaceChange}
            />
            <h3>Längd på rundan(km):</h3>
            <input
              className={classes.singleLineTxtField}
              type='text'
              id='guideLength'
              name='guideLength'
              value={data.guideLength}
              onChange={onGuideLengthChange}
            />
            <h3>Tidsåtgång(minuter):</h3>
            <input
              className={classes.singleLineTxtField}
              type='text'
              id='guideTime'
              name='guideTime'
              value={data.guideTime}
              onChange={onGuideTimeChange}
            />
          </Paper>
        </div>
        <div className={classes.r1R}>
          <div>
            <h3>Rundans startpunkt:</h3>
            <GoogleMapContainer
              latitude={data.latitude === 0 ? 62.89 : data.latitude}
              longitude={data.longitude === 0 ? 15.58 : data.longitude}
              onMarkerDragEnd={onMapsMarkerDragEnd}
              width={mapW}
              height={mapH}
              zoom={data.latitude === 0 ? 4 : undefined}
            />
            <div className={classes.underMap}>
              <h3>Latitude:</h3>
              <input
                className={classes.singleLineTxtField}
                type='text'
                id='lat'
                name='lat'
                value={data.latitude}
                onChange={onLatitudeChange}
              />
              <h3>Longitude:</h3>
              <input
                className={classes.singleLineTxtField}
                type='text'
                id='lng'
                name='lng'
                value={data.longitude}
                onChange={onLongitudeChange}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
export default TourEditor

import { groupBy } from 'lodash'
import { partitionArray } from './utils'
import { isSFXPoint, isInfoPoint, isViaPoint, isTourPoint } from './utils'
import {
  UPDATE_TOURPOINT,
  CHANGE_TOURPOINT_TYPE,
  REORDER_TOURPOINTS,
  REMOVE_TOURPOINT,
  CREATE_TOURPOINT,
  RESET_TOUR_STATE,
  UPDATE_TOUR_FROM_SERVER
} from './utils'
import {
  TOUR_EDITOR,
  TOUR_MEDIA,
  TOUR_NEW_POINT,
  TOUR_NO_VIEW,
  TOUR_POINT_EDITOR,
  TOUR_VIEWER
} from './utils'

const reorderPlaces = arr => {
  const { withoutOrder = [], unordered = [] } = groupBy(arr, v =>
    v.ordernumber === 0 ? 'withoutOrder' : 'unordered'
  )

  const ordered = unordered
    .sort((a, b) => a.ordernumber - b.ordernumber)
    .map((v, i) => {
      return { ...v, ordernumber: i + 1 }
    })
  return withoutOrder.concat(ordered)
}

const tourReducer = (state, action) => {
  const { working } = state
  const { type, payload } = action
  const idPredicate = v => v._id === id
  const { _id: id = null } = payload

  if (!typeof type === 'symbol') throw new Error('action.type is not of type Symbol')

  switch (type) {
    case UPDATE_TOURPOINT: {
      const places = working.places.map(v => {
        return v._id === id ? { ...payload } : { ...v }
      })

      return {
        ...state,
        working: { ...working, places },
        view: {
          ...state.view,
          data: places.find(idPredicate)
        },
        dirty: true
      }
    }

    case CHANGE_TOURPOINT_TYPE: {
      let dirty = true
      const data = { ...payload }
      const [prev_type] = working.places.filter(idPredicate)

      if (data.placeTypeId === prev_type.placeTypeId) {
        dirty = false
      }
      if (
        (isTourPoint(data) || isViaPoint(data)) &&
        (isTourPoint(prev_type) || isViaPoint(prev_type))
      ) {
        /* do nothing - either no change or just from TourPoint to ViaPoint or
         * the otherway around*/
      } else if (
        (isTourPoint(data) || isViaPoint(data)) &&
        (isSFXPoint(prev_type) || isInfoPoint(prev_type))
      ) {
        /* We have an unordered point becoming ordered */
        // Set new point's ordernumber to something large
        data.ordernumber = 10000
      } else if (
        (isSFXPoint(data) || isInfoPoint(data)) &&
        (isTourPoint(prev_type) || isViaPoint(prev_type))
      ) {
        /* We have an ordered point becoming unordered */
        // Set ordernumber to 0 and empty imageAwsIdArray and moviesAwsIdArray
        data.ordernumber = 0
      }

      // sfx and via points cant have images or movies
      if (isSFXPoint(data) || isViaPoint(data)) {
        data.imageAwsIdArray = []
        data.moviesAwsIdArray = []
      }

      const _places = working.places.map(v => {
        return v._id === id ? { ...data } : { ...v }
      })

      const places = reorderPlaces(_places)

      return {
        ...state,
        working: { ...working, places },
        view: {
          ...state.view,
          data: places.find(idPredicate)
        },
        dirty
      }
    }

    case REORDER_TOURPOINTS: {
      let dirty = false
      // fix disparity between indexes and ordernumbers
      const removeIndex = payload.removedIndex + 1
      const addIndex = payload.addedIndex + 1
      const places = working.places.map(i => {
        // ordernumber 0 means not meant to be ordered
        if (i.ordernumber === 0) {
          return i
        }
        // if outside both addIndex and removeIndex nothing changes
        if (
          (i.ordernumber < addIndex && i.ordernumber < removeIndex) ||
          (i.ordernumber > addIndex && i.ordernumber > removeIndex)
        ) {
          return i
        }

        // empty move
        if (addIndex === removeIndex) {
          return i
        }

        // moving item from top further down
        if (addIndex > removeIndex) {
          if (i.ordernumber === removeIndex) {
            dirty = true
            return { ...i, ordernumber: addIndex }
          }
          if (i.ordernumber <= addIndex) {
            dirty = true
            return { ...i, ordernumber: i.ordernumber - 1 }
          }
        }
        // moving item up the list
        if (addIndex < removeIndex) {
          if (i.ordernumber === removeIndex) {
            dirty = true
            return { ...i, ordernumber: addIndex }
          }
          if (i.ordernumber >= addIndex) {
            dirty = true
            return { ...i, ordernumber: i.ordernumber + 1 }
          }
        }
      })

      // Make sure that view is also updated with new ordernumbers
      // if it is a tourpointeditor
      // This code should not be needed when tourpointeditor gets its data from
      // the working.places array
      if (state.view.type === TOUR_POINT_EDITOR) {
        const data = places.find(i => i._id === state.view.data._id)
        return {
          ...state,
          working: { ...working, places },
          view: {
            ...state.view,
            data
          },
          dirty
        }
      }

      return { ...state, dirty, working: { ...working, places } }
    }

    case REMOVE_TOURPOINT: {
      let { view } = state
      const places = working.places.filter(v => v._id !== id)

      const newPlaces = reorderPlaces(places)

      if (view.type === TOUR_POINT_EDITOR && view.data._id === payload._id) {
        view = {
          type: TOUR_NO_VIEW,
          data: {}
        }
      }

      return {
        ...state,
        working: { ...working, places: newPlaces },
        view,
        dirty: true
      }
    }

    case CREATE_TOURPOINT: {
      console.log('in create tourpoint')
      console.log(payload)
      const places = [...working.places, { ...payload, new: true }]

      return {
        ...state,
        working: { ...working, places },
        view: {
          type: TOUR_POINT_EDITOR,
          data: places.find(idPredicate)
        },
        dirty: true
      }
    }

    case RESET_TOUR_STATE: {
      let { view } = state
      if (view.type === TOUR_POINT_EDITOR) {
        const data = state.original.places.find(v => v._id === view.data._id)
        if (data) {
          view = {
            ...view,
            data
          }
        } else {
          view = {
            type: TOUR_NO_VIEW,
            data: {}
          }
        }
      }
      return { ...state, working: { ...state.original }, view, dirty: false }
    }

    case UPDATE_TOUR_FROM_SERVER: {
      console.log(payload)
      let { view } = state
      const data = payload.working.places.find(idPredicate)
      if (view.type === TOUR_POINT_EDITOR) {
        if (data) {
          view = {
            ...view,
            data
          }
        }
      }
      return { ...state, ...payload, ...view }
    }

    case TOUR_POINT_EDITOR: {
      console.log(payload)
      return {
        ...state,
        view: {
          type: TOUR_POINT_EDITOR,
          data: working.places.find(idPredicate)
        }
      }
    }

    case TOUR_MEDIA: {
      return {
        ...state,
        view: {
          type: TOUR_MEDIA,
          data: {
            mediaData: working.contentMap,
            mediaType: payload
          }
        }
      }
    }

    case TOUR_VIEWER: {
      return {
        ...state,
        view: {
          type: TOUR_VIEWER,
          data: {}
        }
      }
    }

    case TOUR_EDITOR: {
      return {
        ...state,
        view: {
          type: TOUR_EDITOR,
          data: {}
        }
      }
    }

    case TOUR_NO_VIEW: {
      return {
        ...state,
        view: {
          type: TOUR_NO_VIEW,
          data: {}
        }
      }
    }

    default:
      throw new Error(`action.type not found ${action.type.toString()}`)
  }
}

export default tourReducer

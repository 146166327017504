import React, { useEffect, useReducer, useState, useContext } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import CssBaseline from '@material-ui/core/CssBaseline'
import AppBar from '@material-ui/core/AppBar'
import Toolbar from '@material-ui/core/Toolbar'
import Typography from '@material-ui/core/Typography'
import Button from '@material-ui/core/Button'
import { TourContext, RouteContext } from './App'
import TourEditor from './TourEditor'
import TourViewer from './TourViewer'
import TourPointEditor from './TourPointEditor'
import TourMedia from './TourMedia'
import TourPageSideBar from './TourPageSideBar'
import { useFetchTour } from './useTour'
import { shuttleApi } from './config'
import {
  TOUR_NO_VIEW,
  TOUR_MEDIA,
  TOUR_POINT_EDITOR,
  TOUR_EDITOR,
  TOUR_NEW_POINT,
  TOUR_VIEWER
} from './utils'
import {
  UPDATE_TOUR_FROM_SERVER,
  CREATE_TOURPOINT,
  RESET_TOUR_STATE
} from './utils'
import { UPDATE_ROUTES_FROM_SERVER, DESELECT_ROUTE } from './utils'

const drawerWidth = 240

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    height: '100vh',
    overflow: 'hidden'
  },
  appBar: {
    width: '100%',
    height: 60,
    color: '#444',
    marginLeft: drawerWidth,
    backgroundColor: '#ccdbf0',
    zIndex: theme.zIndex.drawer + 1
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
    top: 47
  },
  drawerPaper: {
    width: drawerWidth,
    flexShrink: 0
  },
  toolbar: theme.mixins.toolbar,
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
    maxHeight: '100vh',
    overflow: 'auto'
  },
  mainCon: {
    align: 'left',
    textAlign: 'left',
    minHeight: 300
  },
  backBtn: {
    align: 'left',
    textAlign: 'left',
    fontSize: 20,
    fontWeight: 500,
    marginLeft: -10,
    textTransform: 'none'
  },
  addBtn: {
    align: 'center',
    textAlign: 'center',
    fontSize: 14,
    width: '80%',
    textTransform: 'none',
    margin: 'auto',
    backgroundColor: '#528adb',
    color: 'white',
    marginBottom: 10
  },
  sPaper: {
    width: drawerWidth,
    marginBottom: 20,
    padding: 5,
    paddingTop: 5,
    backgroundColor: 'default',
    flexShrink: 0
  },
  alertButton: {
    backgroundColor: 'orange',
    color: 'blue',
    margin: '2px 10px 2px 10px'
  }
}))

const MainContent = ({ page, setPage, tour, route, setSaving }) => {
  const { view } = tour
  console.log(view)
  switch (view.type) {
    case TOUR_EDITOR: {
      return <TourEditor setPage={setPage} data={route.selected} />
    }

    case TOUR_POINT_EDITOR: {
      return (
        <TourPointEditor
          key={view.data._id + view.data.ordernumber}
          data={view.data}
          newPoint={view.data.new}
          reload={!tour.dirty}
        />
      )
    }

    case TOUR_MEDIA: {
      return <TourMedia data={view.data} />
    }

    case TOUR_NO_VIEW: {
      return <div>Välj i menyn</div>
    }

    case TOUR_VIEWER: {
      return <TourViewer data={tour.working} />
    }

    default: {
      return null
    }
  }
}

const TourPage = ({ page, setPage }) => {
  const classes = useStyles()

  const [saving, setSaving] = useState(false)
  const { tour, dispatchTour: dispatch } = useContext(TourContext)
  const { route, dispatchRoute } = useContext(RouteContext)

  useEffect(() => {
    if (route.selected) {
      fetch(`${shuttleApi}/api/tour/${route.selected.tourId}`, {
        credentials: 'include'
      })
        .then(res => res.json())
        .then(res => {
          dispatch({
            type: UPDATE_TOUR_FROM_SERVER,
            payload: {
              original: res,
              working: { ...res },
              dirty: false
            }
          })
        })
        .catch(/*() => setObjects({ hasErrors: true })*/)
    }
  }, [route.selected])

  useEffect(() => {
    if (saving === true) {
      setSaving(false)
      fetch(`${shuttleApi}/api/tour/${route.selected.tourId}`, {
        credentials: 'include',
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(tour.working)
      })
        .then(res => res.json())
        .then(res => {
          setSaving(false)
          dispatch({
            type: UPDATE_TOUR_FROM_SERVER,
            payload: {
              original: res,
              working: { ...res },
              dirty: false
            }
          })
        })
      fetch(`${shuttleApi}/api/routes`, {
        credentials: 'include',
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(route.selected)
      })
        .then(res => res.json())
        .then(res => {
          dispatchRoute({
            type: UPDATE_ROUTES_FROM_SERVER,
            payload: {
              original: { tours: res },
              working: { tours: res },
              selected: res.find(v => v._id === route.selected._id),
              dirty: false
            }
          })
        })
    }
  }, [saving])

  useEffect(() => {
    if (tour.dirty === true || route.dirty) {
      window.onbeforeunload = function() {
        return ''
      }
    } else {
      window.onbeforeunload = null
    }
  }, [tour.dirty, route.dirty])

  const onClickSave = () => setSaving(true)

  const onReturnToTourList = () => {
    dispatch({ type: TOUR_NO_VIEW, payload: {} })
    dispatchRoute({ type: DESELECT_ROUTE, payload: {} })
    setPage({ page: 'ToursListPage' })
  }

  return (
    <div>
      <div className={classes.root}>
        <CssBaseline />
        <AppBar position='fixed' className={classes.appBar}>
          <Toolbar>
            <div
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                width: '100%'
              }}
            >
              <div>
                <Typography variant='h6' noWrap>
                  <Button
                    className={classes.backBtn}
                    onClick={onReturnToTourList}
                  >
                    &#8678; Shuttle
                  </Button>
                  {route.selected.titles.sv}
                </Typography>
              </div>
              <div style={{ display: 'flex' }}>
                {((tour.dirty || route.dirty) && (saving === false)) && (
                  <>
                    <Button
                      className={classes.alertButton}
                      variant='contained'
                      color='primary'
                      onClick={onClickSave}
                    >
                      Spara
                    </Button>
                    <Button
                      className={classes.alertButton}
                      color='primary'
                      variant='contained'
                      onClick={() =>
                        dispatch({ type: RESET_TOUR_STATE, payload: {} })
                      }
                    >
                      Ångra
                    </Button>
                  </>
                )}
              </div>
            </div>
          </Toolbar>
        </AppBar>
        <TourPageSideBar
          classes={classes}
          page={page}
          setPage={setPage}
          tour={tour.working}
          selectedRoute={route.selected}
          dispatch={dispatch}
        />
        <main className={classes.content}>
          <Toolbar />
          <div id='MainCont' className={classes.mainCon}>
            <MainContent
              setPage={setPage}
              page={page}
              tour={tour}
              route={route}
              dispatch={dispatch}
              setSaving={setSaving}
            />
          </div>
        </main>
      </div>
    </div>
  )
}

export default TourPage

import React, { useContext, useState, useEffect, useCallback } from 'react'
import { withStyles, makeStyles, ThemeProvider } from '@material-ui/core/styles'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableContainer from '@material-ui/core/TableContainer'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import Paper from '@material-ui/core/Paper'
import { AccountContext, RouteContext } from './App'
import CssBaseline from '@material-ui/core/CssBaseline'
import AppBar from '@material-ui/core/AppBar'
import Toolbar from '@material-ui/core/Toolbar'
import Typography from '@material-ui/core/Typography'
import Button from '@material-ui/core/Button'
import { shuttleApi } from './config.js'

import {
  Input,
  FormControl,
  FormHelperText,
  InputLabel,
  FormGroup
} from '@material-ui/core'

import { DESELECT_ROUTE, TOUR_NO_VIEW } from './utils'

const UserPage = ({ setPage }) => {
  const [oldPassword, setOldPassword] = useState('')
  const [password1, setPassword1] = useState('')
  const [password2, setPassword2] = useState('')
  const onSubmit = (e) => {
    e.preventDefault()
    fetch(`${shuttleApi}/api/changepassword`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      credentials: 'include',
      body: JSON.stringify({ oldPassword, password1, password2 })
    })
      .then((res) => res.json())
      .then((res) => {
        if (res.status === 'failed') {
          alert('Ditt gamla löseord stämde inte')
        } else {
          alert('Ditt löseord är ändrat')
        }
        console.log(res)
      })
  }

  const validateForm = () => {
    return (
      oldPassword.length > 0 &&
      password1.length > 0 &&
      password2.length > 0 &&
      password1 === password2
    )
  }
  const useStyles = makeStyles((theme) => ({
    table: {
      width: '70%'
    },
    table2: {
      width: 400
    },
    appBar: {
      width: '100%',
      height: 60,
      color: '#444',
      backgroundColor: '#ccdbf0',
      zIndex: theme.zIndex.drawer + 1
    },

    backBtn: {
      align: 'left',
      textAlign: 'left',
      fontSize: 20,
      fontWeight: 500,
      marginLeft: -10,
      textTransform: 'none'
    },
    InputField: {
      marginRight: 20
    }
  }))
  const { account, dispatchAccount: dispatch } = useContext(AccountContext)
  const { route } = useContext(RouteContext)
  const classes = useStyles()
  const { dispatchRoute } = useContext(RouteContext)

  const onReturnToTourList = () => {
    setPage({ page: 'ToursListPage' })
  }

  const onLogOut = async () => {
    try {
      const res = await fetch(`${shuttleApi}/api/logout`, {
        headers: {
          'Content-Type': 'application/json'
        },
        credentials: 'include'
      })
      document.cookie =
        'cookieConsent= ; expires = Thu, 01 Jan 1970 00:00:00 GMT'
      setPage({ page: 'login' })
    } catch (err) {}
  }

  const StyledTableCell = withStyles((theme) => ({
    head: {
      backgroundColor: theme.palette.common.black,
      color: theme.palette.common.white
    },
    body: {
      fontSize: 14
    }
  }))(TableCell)

  const StyledTableRow = withStyles((theme) => ({
    root: {
      '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.action.hover
      }
    }
  }))(TableRow)

  console.log(account)
  return (
    <div>
      <AppBar position='fixed' className={classes.appBar}>
        <Toolbar>
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              width: '100%'
            }}
          >
            <div>
              <Typography variant='h6' noWrap>
                <Button
                  className={classes.backBtn}
                  onClick={onReturnToTourList}
                >
                  &#8678; Shuttle - Dina inställningar
                </Button>
              </Typography>
            </div>
            <div>
              <Typography variant='h6' noWrap>
                <Button className={classes.backBtn} onClick={onLogOut}>
                  Logga ut
                </Button>
              </Typography>
            </div>
          </div>
        </Toolbar>
      </AppBar>
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center'
        }}
      >
        <h3>UserPage {account.user.id}</h3>
        <br />
        <br />
        <br />
        <Paper elevation={3}>
          <div
            style={{
              display: 'flex',
              marginLeft: '10px',
              marginBottom: '-10px'
            }}
          >
            <h4>Ändra lösenord:</h4>
          </div>
          <form
            style={{
              display: 'inline-flex',
              justifyContent: 'center',
              alignItems: 'flex-start',
              padding: '20px',
              borderStyle: 'solid #333',
              borderRadius: '5px',
              borderColor: '#999',
              borderWidth: '10px'
            }}
            onSubmit={onSubmit}
          >
            <FormControl required className={classes.InputField}>
              <InputLabel htmlFor='oldPassword'>Nuvarande lösenord</InputLabel>
              <Input
                id='oldPassword'
                type='password'
                aria-describedby='forgot-password'
                value={oldPassword}
                onChange={(e) => setOldPassword(e.target.value)}
              />
            </FormControl>
            <FormControl required className={classes.InputField}>
              <InputLabel htmlFor='password1'>Nytt lösenord</InputLabel>
              <Input
                id='password1'
                type='password'
                aria-describedby='forgot-password'
                value={password1}
                onChange={(e) => setPassword1(e.target.value)}
              />
            </FormControl>
            <FormControl required className={classes.InputField}>
              <InputLabel htmlFor='password2'>Nytt lösenord igen</InputLabel>
              <Input
                id='password2'
                type='password'
                aria-describedby='forgot-password'
                value={password2}
                onChange={(e) => setPassword2(e.target.value)}
              />
            </FormControl>
            <div
              style={{ display: 'flex', marginTop: 15, alignItems: 'flex-end' }}
            >
              <Button
                type='submit'
                variant='contained'
                disabled={!validateForm()}
              >
                Ändra
              </Button>
            </div>
          </form>
        </Paper>

        <br />
        <br />
        <br />
        <TableContainer component={Paper} className={classes.table}>
          <Table aria-label='customized table'>
            <TableHead>
              <TableRow>
                <StyledTableCell>Dina rundor</StyledTableCell>
                <StyledTableCell align='right'>Antal startade</StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {route.working.tours != null &&
                route.working.tours.map((val, index) => {
                  return (
                    <StyledTableRow>
                      <StyledTableCell align='left'>
                        {val.titles.sv}
                      </StyledTableCell>
                      <StyledTableCell align='right'>
                        {val.viewStats}
                      </StyledTableCell>
                    </StyledTableRow>
                  )
                })}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
    </div>
  )
}
export default UserPage

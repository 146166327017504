import React from 'react'
import { ObjectID } from 'bson'

const checkMediaType = (label) => (v) => v.contentTypeGroup === label
export const isImageType = checkMediaType('HTML_IMAGE')
export const isSoundType = checkMediaType('SOUND')
export const isVideoType = checkMediaType('VIDEO')

const checkTourPointType = (i) => (v) => v.placeTypeId === i
export const isTourPoint = checkTourPointType(1)
export const isInfoPoint = checkTourPointType(2)
export const isSFXPoint = checkTourPointType(3)
export const isViaPoint = checkTourPointType(4)

export const tourPointTypesArray = Object.freeze(
  Object.seal([
    { placeTypeId: 1, name: 'Rundpunkt' },
    { placeTypeId: 2, name: 'Infopunkt' },
    { placeTypeId: 3, name: 'Ljudpunkt' },
    { placeTypeId: 4, name: 'Viapunkt' }
  ])
)

export const getTourPointTypeString = (i) => {
  if (isTourPoint(i)) return tourPointTypesArray[0].name
  if (isInfoPoint(i)) return tourPointTypesArray[1].name
  if (isSFXPoint(i)) return tourPointTypesArray[2].name
  if (isViaPoint(i)) return tourPointTypesArray[3].name
}

export const createNewPointJSON = () => {
  const objid = new ObjectID()
  return {
    title: {
      sv: ''
    },
    placeTypeId: 4,
    ordernumber: 1000,
    htmlContent: {
      en: '',
      sv: ''
    },
    imagesArray: [],
    soundsArray: [],
    moviesArray: [],
    _id: objid.toString(),
    latitude: '0',
    longitude: '0',
    radius: '15',
    numpadId: 0
  }
}

// Views - Tour
export const TOUR_NO_VIEW = Symbol('TOUR_NO_VIEW')
export const TOUR_MEDIA = Symbol('TOUR_MEDIA')
export const TOUR_POINT_EDITOR = Symbol('TOUR_POINT_EDITOR')
export const TOUR_NEW_POINT = Symbol('TOUR_NEW_POINT')
export const TOUR_EDITOR = Symbol('TOUR_EDITOR')
export const TOUR_VIEWER = Symbol('TOUR_VIEWER')
// Actions - Tour
export const UPDATE_TOURPOINT = Symbol('UPDATE_TOURPOINT')
export const CHANGE_TOURPOINT_TYPE = Symbol('CHANGE_TOURPOINT_TYPE')
export const REORDER_TOURPOINTS = Symbol('REORDER_TOURPOINTS')
export const REMOVE_TOURPOINT = Symbol('REMOVE_TOURPOINT')
export const CREATE_TOURPOINT = Symbol('CREATE_TOURPOINT')
export const RESET_TOUR_STATE = Symbol('RESET_TOUR_STATE')
export const UPDATE_TOUR_FROM_SERVER = Symbol('UPDATE_TOUR_FROM_SERVER')

// Actions - Route
export const SELECT_ROUTE = Symbol('SELECT_ROUTE')
export const DESELECT_ROUTE = Symbol('DESELECT_ROUTE')
export const CREATE_ROUTE = Symbol('CREATE_ROUTE')
export const REMOVE_ROUTE = Symbol('REMOVE_ROUTE')
export const UPDATE_ROUTE = Symbol('UPDATE_ROUTE')
export const RESET_ROUTES_STATE = Symbol('RESET_ROUTE_STATE')
export const UPDATE_ROUTES_FROM_SERVER = Symbol('UPDATE_ROUTES_FROM_SERVER')

// Actions - Account
export const CREATE_ACCOUNT = Symbol('CREATE_ACCOUNT')
export const REMOVE_ACCOUNT = Symbol('REMOVE_ACCOUNT')
export const UPDATE_ACCOUNT = Symbol('UPDATE_ACCOUNT')
export const RESET_ACCOUNT_STATE = Symbol('RESET_ACCOUNT_STATE')
export const UPDATE_ACCOUNT_FROM_SERVER = Symbol('UPDATE_ACCOUNT_FROM_SERVER')

// Hook
export const useDebounce = (value, delay) => {
  const [debouncedValue, setDebouncedValue] = React.useState(value)

  React.useEffect(() => {
    if (value.dirty === true) {
      const handler = setTimeout(() => {
        setDebouncedValue(value)
      }, delay)

      return () => {
        clearTimeout(handler)
      }
    }
  }, [value, delay])

  return debouncedValue
}

import React, { useState, useEffect, useContext } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import styled from 'styled-components'
import Button from '@material-ui/core/Button'
import Grid from '@material-ui/core/Grid'
import Card from '@material-ui/core/Card'
import CardActionArea from '@material-ui/core/CardActionArea'
import CardContent from '@material-ui/core/CardContent'
import Typography from '@material-ui/core/Typography'
import CardMedia from '@material-ui/core/CardMedia'
import ReactHtmlParser from 'react-html-parser'
import { RouteContext, AccountContext } from './App'
import MissingImage from './Missing-image-232x150.png'
import { shuttleApi, mediaUrl } from './config.js'
import {
  UPDATE_ROUTES_FROM_SERVER,
  SELECT_ROUTE,
  SELECT_USER,
  CREATE_ROUTE
} from './utils'

const useStyles = makeStyles({
  mGrid: {
    width: '75%',
    margin: 'auto'
  },
  cards: {
    height: 300
  },
  MainTitle: {
    fontSize: 25,
    color: 'black',
    marginTop: 10,
    marginBottom: 20
  },
  title: {
    fontSize: 16,
    color: 'black',
    height: 20
  },
  intro: {
    height: 110,
    fontSize: 14,
    overflow: 'hidden'
  },
  pos: {
    marginBottom: 12
  },
  media: {
    height: 140
  },
  newImg: {
    height: 160,
    width: 160,
    margin: 'auto',
    marginBottom: 20
  },
  openBtn: {
    marginBottom: 32,
    alignmentBaseline: 'right'
  },
  settingsBar: {
    display: 'flex',
    width: '75%',
    margin: 'auto',
    height: 60,
    color: '#444',
    backgroundColor: '#ccdbf0',
    alignItems: 'stretch'
  }
})

const ToursListPage = ({ page, setPage }) => {
  const classes = useStyles()

  const { route, dispatchRoute: dispatch } = useContext(RouteContext)
  const { account } = useContext(AccountContext)

  useEffect(() => {
    fetch(`${shuttleApi}/api/routes`, {
      credentials: 'include'
    })
      .then((res) => res.json())
      .then((res) => {
        dispatch({
          type: UPDATE_ROUTES_FROM_SERVER,
          payload: {
            original: { tours: res },
            working: { tours: res },
            dirty: false
          }
        })
      })
      .catch()
  }, [])

  const onClickUser = () => {
    setPage({ page: 'UserPage' })
  }

  const onClickTour = (value) => () => {
    dispatch({ type: SELECT_ROUTE, payload: value })
    setPage({ page: 'TourPage' })
  }

  const onClickNew = async () => {
    try {
      const resCreate = await fetch(`${shuttleApi}/api/route/create`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        credentials: 'include',
        body: JSON.stringify({})
      })
      const newTour = await resCreate.json()
      const resRoute = await fetch(`${shuttleApi}/api/routes`, {
        credentials: 'include'
      })
      const tours = await resRoute.json()
      console.log(newTour)
      console.log(tours)
      const found = tours.find((v) => v._id === newTour._id)
      console.log(found)
      dispatch({
        type: UPDATE_ROUTES_FROM_SERVER,
        payload: {
          original: { tours: tours },
          working: { tours: tours },
          selected: tours.find((v) => v._id === newTour._id),
          dirty: false
        }
      })
      setPage({ page: 'TourPage' })
    } catch (err) {
      console.log(err)
    }
  }

  const handleImageError = (e) => {
    e.target.onerror = null
    e.target.src = MissingImage
  }

  return (
    <div>
      <link
        rel='stylesheet'
        href='https://fonts.googleapis.com/css?family=Roboto:300,400,500,700&display=swap'
      />
      <link
        href='https://fonts.googleapis.com/css?family=Baloo+Da+2|Source+Sans+Pro&display=swap'
        rel='stylesheet'
      />

      <div className={classes.MainTitle}>Shuttle</div>
      <div className={classes.settingsBar}>
        <div
          style={{
            display: 'flex',
            marginLeft: '10px',
            marginTop: 'auto',
            marginBottom: '10px',
            width: '100%'
          }}
        >
          <h4>Dina Utställningar</h4>
        </div>
        <div
          style={{
            justifyContent: 'right'
          }}
        >
          <Button
            onClick={onClickUser}
            style={{
              fontSize: 40,
              padding: 0
            }}
          >
            &#9881;
          </Button>
        </div>
      </div>
      <Grid container className={classes.mGrid} spacing={3}>
        <Grid item xs={3}>
          <Card className={classes.cards} elevation={5}>
            <CardActionArea onClick={onClickNew}>
              <CardContent>
                <Typography
                  className={classes.title}
                  color='textSecondary'
                  gutterBottom
                />

                <CardMedia
                  className={classes.newImg}
                  image={
                    'https://pbs.twimg.com/profile_images/506394721812373504/IDmMstyJ.jpeg'
                  }
                  component='img'
                  title='Guide image'
                />
                <Typography
                  className={classes.intro}
                  color='textSecondary'
                  gutterBottom
                >
                  Lägg till ny utställning
                </Typography>
              </CardContent>
            </CardActionArea>
          </Card>
        </Grid>
        {route.working.tours != null &&
          route.working.tours.map((val, index) => {
            return (
              <Grid item xs={3}>
                <Card className={classes.cards} elevation={5}>
                  <CardActionArea onClick={onClickTour(val)}>
                    <CardContent>
                      <Typography
                        className={classes.title}
                        color='textSecondary'
                        gutterBottom
                      >
                        {val.titles.sv}
                      </Typography>

                      <CardMedia
                        className={classes.media}
                        image={`${mediaUrl}/${val.tourId}/images/${val.guideImage}`}
                        onError={handleImageError}
                        component='img'
                        title='Guide image'
                      />
                      <Typography
                        className={classes.intro}
                        color='textSecondary'
                        gutterBottom
                      >
                        {ReactHtmlParser(val.htmlContent.sv)}
                      </Typography>
                      <LabelContainer>
                        {val.published && <Published>Published</Published>}
                        {val.code.active && <Locked>{val.code.number}</Locked>}
                      </LabelContainer>
                    </CardContent>
                  </CardActionArea>
                </Card>
              </Grid>
            )
          })}
      </Grid>
    </div>
  )
}

const LabelContainer = styled.div`
  display: grid;
  position: relative;
  grid-template-columns: 1fr 1fr;
  bottom: 20px;
`

const Published = styled.div`
  position: absolute;
  background-color: green;
  color: white;
  transform: rotate(45deg);
  width: 100%;
  padding: 1px;
  left: -45%;
  bottom: 0%;
  font-weight: 900;
`

const Locked = styled.div`
  position: absolute;
  background-color: grey;
  color: white;
  transform: rotate(315deg);
  letter-spacing: 2px;
  width: 100%;
  padding: 1px;
  right: -45%;
  bottom: 0%;
  font-weight: 900;
`

export default ToursListPage

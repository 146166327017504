import React, { useContext, useState, useEffect, useCallback } from 'react'
import GoogleMapContainer from './gMaps'
import Grid from '@material-ui/core/Grid'
import TextField from '@material-ui/core/TextField'
import { makeStyles } from '@material-ui/core/styles'
import Paper from '@material-ui/core/Paper'
import Tooltip from '@material-ui/core/Tooltip'
import { EditorState, ContentState, convertFromHTML } from 'draft-js'
import { stateToHTML } from 'draft-js-export-html'
import { Editor } from 'react-draft-wysiwyg'
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css'
import { blue } from '@material-ui/core/colors'
import { Table } from '@material-ui/core'
import Button from '@material-ui/core/Button'
import Select, { components } from 'react-select'
import './GlobalStyle.css'
import { TourContext } from './App'
import { mediaUrl } from './config'
import ChangeTourPointTypeDialog from './ChangeTourPointTypeDialog'
import { useDebounce } from './utils'
import { TOUR_POINT_EDITOR } from './utils'
import {
  isImageType,
  isSoundType,
  isVideoType,
  isTourPoint,
  isInfoPoint,
  isSFXPoint,
  isViaPoint,
  getTourPointTypeString
} from './utils'
import {
  CHANGE_TOURPOINT_TYPE,
  UPDATE_TOURPOINT,
  REMOVE_TOURPOINT
} from './utils'

const mapW = 400
const mapH = 400

const useStyles = makeStyles({
  main: {
    display: 'flex',
    flexWrap: 'wrap',
    //flexDirection: 'column',
    width: '100%'
  },
  r1: {
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
    flexFlow: 'column wrap'
  },
  r1L: {
    flex: `calc(100% - ${mapW}px - 10px)`,
    marginRight: 20
  },
  r1R: {
    flex: mapW,
    marginTop: 0,
    marginRight: '0px',
    flexDirection: 'column'
  },
  underMap: {
    marginTop: mapH + 20,
    width: '100%'
  },

  singleLineTxtField: {
    width: '100%',
    marginLeft: 0,
    fontSize: 20,
    paddingTop: 5
  },

  mGrid: {
    width: '30%',
    marginRight: 20,
    marginLeft: 20,
    backgroundColor: '#009900'
  },
  mLeft: {},

  mPaper: {
    marginBottom: 20,
    padding: 20,
    paddingTop: 5
  },
  mDropper: {
    width: '80%',
    marginTop: 10,
    marginBottom: 10,
    padding: 20,
    paddingTop: 5,
    fontSize: 16
  }
})

const MultiImageValue = (props) => {
  const value = props.getValue()[props.index]
  const id = props.selectProps.selectProps.id
  const image = `${mediaUrl}/${id}/images/${value._id}`
  return (
    <components.MultiValue {...props}>
      <Tooltip title={value.name}>
        <div>
          <img
            style={{ width: '100px', height: '100px' }}
            src={image}
            alt={value.name}
          />
        </div>
      </Tooltip>
    </components.MultiValue>
  )
}

const SingleSoundValue = (props) => {
  const id = props.selectProps.selectProps.id
  const sound = `${mediaUrl}/${id}/sounds/${props.data._id}`
  return (
    <div style={{ height: '4rem' }}>
      <components.SingleValue {...props}>
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <div>
            <audio controls>
              <source src={sound} type='audio/mpeg' />{' '}
            </audio>
          </div>
          <div style={{ paddingLeft: '1rem' }}>{props.data.name}</div>
        </div>
      </components.SingleValue>
    </div>
  )
}

const SingeMovieValue = (props) => {
  const id = props.selectProps.selectProps.id
  const movie = `${mediaUrl}/${id}/movies/${props.data._id}`
  return (
    <div style={{ height: '200px' }}>
      <components.SingleValue {...props}>
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <div>
            <video
              style={{ height: '200px', width: '300px' }}
              controls
              playsInline
            >
              <source src={movie} type='video/mp4' />
            </video>
          </div>
          <div style={{ paddingLeft: '1rem' }}>{props.data.name}</div>
        </div>
      </components.SingleValue>
    </div>
  )
}

const ImageOption = (props) => {
  const id = props.selectProps.selectProps.id
  const image = `${mediaUrl}/${id}/images/${props.data._id}`
  return (
    <components.Option {...props}>
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <img
          style={{ width: '50px', height: '50px' }}
          src={image}
          alt={props.data.name}
        />
        <div style={{ paddingLeft: 50 }}>{props.data.name}</div>
      </div>
    </components.Option>
  )
}

const NameOnlyOption = (props) => {
  return (
    <components.Option {...props}>
      <div>{props.data.name}</div>
    </components.Option>
  )
}

const SoundOption = NameOnlyOption
const MovieOption = NameOnlyOption

const TourPointEditor = ({ data, newPoint, reload }) => {
  const classes = useStyles()
  const initialState = {
    editorState: EditorState.createWithContent(
      ContentState.createFromBlockArray(convertFromHTML(data.htmlContent.sv))
    ),
    dirty: false
  }

  const { tour, dispatchTour: dispatch } = useContext(TourContext)
  const [state, setState] = useState(initialState)
  const [typeDialogOpen, setTypeDialogOpen] = useState(false)

  const debouncedEditorSave = useDebounce(state, 300)

  useEffect(() => {
    console.log('inside first use effect')
    setState({
      ...state,
      editorState: EditorState.moveFocusToEnd(
        EditorState.createWithContent(
          ContentState.createFromBlockArray(
            convertFromHTML(data.htmlContent.sv)
          )
        )
      ),
      dirty: false
    })
  }, [data._id])

  useEffect(() => {
    if (newPoint && !typeDialogOpen) {
      setTypeDialogOpen(true)
    }
  }, [newPoint])

  useEffect(() => {
    if (debouncedEditorSave && state.dirty === true) {
      console.log('updating from editor')
      dispatch({
        type: UPDATE_TOURPOINT,
        payload: {
          ...data,
          htmlContent: {
            ...data.htmlContent,
            sv: stateToHTML(debouncedEditorSave.editorState.getCurrentContent())
          }
        }
      })
      setState({ ...state, dirty: false })
    }
  }, [debouncedEditorSave])

  const onEditorStateChange = (editorState) => {
    if (editorState._immutable.lastChangeType != null) {
      console.log('we are in editorstate')
      console.log(stateToHTML(editorState.getCurrentContent()))
      setState({
        ...state,
        editorState,
        dirty: true
      })
    }
  }

  const setOnMediaChange = (field) => (value) => {
    dispatch({
      type: UPDATE_TOURPOINT,
      payload: {
        ...data,
        [`${field}`]: value == null ? [] : [value].flat()
      }
    })
  }

  const onImageChange = setOnMediaChange('imagesArray')
  const onSoundChange = setOnMediaChange('soundsArray')
  const onMoviesChange = setOnMediaChange('moviesArray')

  const onTitleChange = (e) => {
    dispatch({
      type: UPDATE_TOURPOINT,
      payload: {
        ...data,
        title: { ...data.title, sv: e.target.value }
      }
    })
  }

  const onSimpleFieldChange = (field) => (e) => {
    dispatch({
      type: UPDATE_TOURPOINT,
      payload: {
        ...data,
        [`${field}`]: e.target.value
      }
    })
  }

  const onLatitudeChange = onSimpleFieldChange('latitude')
  const onLongitudeChange = onSimpleFieldChange('longitude')
  const onRadiusChange = onSimpleFieldChange('radius')

  const onNumpadChange = (e) => {
    const number = (e.target.value === '') ? '' : parseInt(e.target.value)
    if ((typeof number === 'number' && !isNaN(number)) || number === '') {
      dispatch({
        type: UPDATE_TOURPOINT,
        payload: {
          ...data,
          numpadId: number || 0
        }
      })
    }
  }

  const onMapsMarkerDragEnd = useCallback(
    (coordinates) => {
      const { latLng } = coordinates
      dispatch({
        type: UPDATE_TOURPOINT,
        payload: {
          ...data,
          latitude: latLng.lat(),
          longitude: latLng.lng()
        }
      })
    },
    [data]
  )

  const onChangeTourType = (value) => {
    if (value) {
      let newData = data
      newData.new && delete newData.new
      dispatch({
        type: CHANGE_TOURPOINT_TYPE,
        payload: {
          ...newData,
          placeTypeId: value
        }
      })
    } else {
      data.new && dispatch({ type: REMOVE_TOURPOINT, payload: data })
    }
    setTypeDialogOpen(false)
  }

  const onClickChangeTourType = () => {
    setTypeDialogOpen(true)
  }

  return (
    <>
      <div className={classes.main}>
        <div className={classes.r1}>
          <div className={classes.r1L}>
            <Paper elevation={1} className={classes.mPaper}>
              <div
                style={{
                  display: 'flex',
                  width: '30%',
                  alignItems: 'center',
                  textAlign: 'center'
                }}
              >
                <div style={{ paddingRight: 10 }}>
                  <h3>Punkt Typ:</h3>
                </div>
                <div>
                  <Button variant='contained' onClick={onClickChangeTourType}>
                    {getTourPointTypeString(data)}
                  </Button>
                </div>
              </div>
            </Paper>
            <Paper elevation={1} className={classes.mPaper}>
              <h3>Titel:</h3>
              <input
                className={classes.singleLineTxtField}
                type='text'
                id='title'
                name='title'
                value={data.title.sv}
                onChange={onTitleChange}
              />
            </Paper>
            {isTourPoint(data) || isInfoPoint(data) ? (
              <Paper elevation={1} className={classes.mPaper}>
                <h3>Text:</h3>
                <Editor
                  toolbar={{
                    options: ['inline', 'list', 'fontSize'],
                    inline: {
                      inDropdown: false,
                      className: undefined,
                      component: undefined,
                      dropdownClassName: undefined,
                      options: ['bold', 'italic', 'underline']
                    }
                  }}
                  toolbarClassName='toolbarClassName'
                  wrapperClassName='home-wrapper'
                  editorClassName='home-editor'
                  editorState={state.editorState}
                  //value={data.htmlContent.sv}
                  onEditorStateChange={onEditorStateChange}
                  defaultValue={data.htmlContent.sv}
                />
              </Paper>
            ) : null}
            {isTourPoint(data) || isInfoPoint(data) ? (
              <Paper elevation={1} className={classes.mPaper}>
                <h3>Bilder</h3>
                {data.imagesArray != null && (
                  <div>
                    <Select
                      classNamePrefix='react-select'
                      placeholder={'Välj bilder...'}
                      value={data.imagesArray || null}
                      selectProps={{ id: tour.working._id }}
                      getOptionValue={(option) => option._id}
                      getLabelValue={(option) => option.name}
                      components={{
                        Option: ImageOption,
                        MultiValue: MultiImageValue
                      }}
                      onChange={onImageChange}
                      options={tour.working.contentMap.filter(isImageType)}
                      menuPlacement='auto'
                      openMenuOnClick={true}
                      closeMenuOnSelect={false}
                      isMulti
                      isSearchable={false}
                    />
                  </div>
                )}
              </Paper>
            ) : null}
            {isTourPoint(data) || isInfoPoint(data) || isSFXPoint(data) ? (
              <Paper elevation={1} className={classes.mPaper}>
                <h3>Ljud</h3>
                {data.soundsArray != null && (
                  <div>
                    <Select
                      classNamePrefix='react-select'
                      placeholder={'Välj ljud...'}
                      value={data.soundsArray[0] || null}
                      selectProps={{ id: tour.working._id }}
                      getOptionValue={(option) => option._id}
                      getLabelValue={(option) => option.name}
                      components={{
                        Option: SoundOption,
                        SingleValue: SingleSoundValue
                      }}
                      isClearable={true}
                      onChange={onSoundChange}
                      options={tour.working.contentMap.filter(isSoundType)}
                      menuPlacement='auto'
                      openMenuOnClick={true}
                      closeMenuOnSelect={true}
                    />
                  </div>
                )}
              </Paper>
            ) : null}
            {isTourPoint(data) || isInfoPoint(data) ? (
              <Paper elevation={1} className={classes.mPaper}>
                <h3>Film</h3>
                {data.moviesArray != null && (
                  <div>
                    <Select
                      classNamePrefix='react-select'
                      placeholder={'Välj film...'}
                      value={data.moviesArray[0] || null}
                      selectProps={{ id: tour.working._id }}
                      getOptionValue={(option) => option._id}
                      getLabelValue={(option) => option.name}
                      components={{
                        Option: MovieOption,
                        SingleValue: SingeMovieValue
                      }}
                      isClearable={true}
                      onChange={onMoviesChange}
                      options={tour.working.contentMap.filter(isVideoType)}
                      menuPlacement='auto'
                      openMenuOnClick={true}
                      closeMenuOnSelect={true}
                    />
                  </div>
                )}
              </Paper>
            ) : null}
          </div>
          <div className={classes.r1R}>
            <div>
              <GoogleMapContainer
                latitude={data.latitude}
                longitude={data.longitude}
                width={mapW}
                height={mapH}
                onMarkerDragEnd={onMapsMarkerDragEnd}
              />
            </div>
            <div className={classes.underMap}>
              <h3>Latitude:</h3>
              <input
                className={classes.singleLineTxtField}
                type='text'
                id='lat'
                name='lat'
                value={data.latitude}
                onChange={onLatitudeChange}
              />
              <h3>Longitude:</h3>
              <input
                className={classes.singleLineTxtField}
                type='text'
                id='lng'
                name='lng'
                value={data.longitude}
                onChange={onLongitudeChange}
              />
              <h3>Radie:</h3>
              <input
                className={classes.singleLineTxtField}
                type='text'
                id='rad'
                name='rad'
                value={data.radius}
                onChange={onRadiusChange}
              />
              {isTourPoint(data) || isInfoPoint(data) ? (
                <>
                  <h3>Numpad-Nummer:</h3>
                  <input
                    className={classes.singleLineTxtField}
                    type='text'
                    id='numpadId'
                    name='numpadId'
                    value={data.numpadId}
                    onChange={onNumpadChange}
                  />
                </>
              ) : null}
            </div>
          </div>
        </div>
      </div>
      <ChangeTourPointTypeDialog
        open={typeDialogOpen}
        value={data.placeTypeId}
        onClose={onChangeTourType}
      />
    </>
  )
}

export default TourPointEditor

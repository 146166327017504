import { Map, InfoWindow, Marker, GoogleApiWrapper } from 'google-maps-react'
import React from 'react'

const mapStyles = {
  width: 300,
  marginRight: 20,
  height: 300
}

const GoogleMapContainer = React.memo(
  ({
    google,
    latitude,
    longitude,
    onMarkerDragEnd,
    width,
    height,
    zoom = 14
  }) => {
    return (
      <Map
        google={google}
        zoom={zoom}
        containerStyle={{ position: 'relative' }}
        style={{
          width,
          height
        }}
        initialCenter={{ lat: latitude, lng: longitude }}
        center={{ lat: latitude, lng: longitude }}
        disableDoubleClickZoom
        onDblclick={(e, a, coord, ...rest) => {
          onMarkerDragEnd(coord)
        }}
      >
        <Marker
          title={'The marker`s title will appear as a tooltip.'}
          draggable={true}
          onDragend={(t, map, coord) => onMarkerDragEnd(coord)}
          name={'SOMA'}
          position={{ lat: latitude, lng: longitude }}
        />
      </Map>
    )
  }
)

export default GoogleApiWrapper({
  apiKey: 'AIzaSyA6jdWmCAWoFUMrfWoi9j6-tFpyVTz54ns'
})(GoogleMapContainer)
